/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c1e9f7b4-6263-47a6-99c7-b860adc45a47",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ECbLLfaNe",
    "aws_user_pools_web_client_id": "4jgebb3fvavga4qet84k9i2omu",
    "oauth": {}
};


export default awsmobile;
